import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import DDIOptions from 'src/app/data/ddi';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService, FundValues } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';

type ProviderValues = {
  fund: number;
  service: string;
  user: string;
  serviceLabel: string;
};
@Component({
  selector: 'app-dashboard-fund-approval-register-providers',
  templateUrl: './dashboard-fund-approval-register-providers.component.html',
  styleUrls: ['./dashboard-fund-approval-register-providers.component.scss'],
})
export class DashboardFundApprovalRegisterProvidersComponent implements OnInit {
  DDIOptions = DDIOptions;

  form = this.formBuilder.group({
    admin: [null, []],
    custodian: [null, []],
    manager: [null, []],
    controller: [null, []],
    coordinator: [null, []],
    agent: [null, []],
    consultant: [null, []],
  });

  newAgentForm = this.formBuilder.group({
    function: [null, [Validators.required]],
    function_user: [null, [Validators.required]],
  });

  availableAgents = [
    { label: 'Administrador', value: 'admin' },
    { label: 'Custodiante', value: 'custodian' },
    { label: 'Controlador', value: 'controller' },
    { label: 'Gestor', value: 'manager' },
    { label: 'Coordenador', value: 'coordinator' },
    { label: 'Distribuidor', value: 'distribuition' },
    { label: 'Consultoria', value: 'consulting' },
    { label: 'Agente de Cobrança', value: 'agent' },
    { label: 'Consultor', value: 'consultant' },
    { label: 'Escriturador', value: 'bookkeeper' },
  ];

  options = {
    admin: [],
    custodian: [],
    controller: [],
    manager: [],
    coordinator: [],
    distribuition: [],
    consulting: [],
    agent: [],
    consultant: [],
    bookkeeper: [],
  };

  selectedOptions: any = [];

  fund: FundValues = undefined;
  additionalProvidersArr: ProviderValues[] = [];

  choices: { [Key in any]: any } = {};

  showForm = false;

  constructor(
    private formBuilder: FormBuilder,
    public fundService: FundService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((status) => {
      Utils.getErrors(this.form);
    });

    this.choices = this.fundService.choices;
    this.handleGetAgentOptions();

    this.newAgentForm.controls.function.valueChanges.subscribe((value) => {
      this.newAgentForm.controls.function_user.setValue(null);
      this.newAgentForm.controls.function_user.reset();

      this.selectedOptions = this.options[value];
    });

    this.form.valueChanges.subscribe((values) => {
      this.updateFundServiceProviders();
    });

    this.form.statusChanges.subscribe((status) => {
      this.fundService.setFundFormStatus('providers', status === 'VALID');
    });
  }

  setData(values: FundValues) {
    this.fund = values;

    if (values) {
      this.form.patchValue({
        ...values,
      });

      if (values.service_providers) {
        values.service_providers.forEach((item) =>
          this.handleSetProviders(item)
        );
      }
    }

    this.fundService.setFundFormStatus('providers', this.form.valid);
  }

  handleSetProviders(provider: any) {
    if (provider.agent_data) {
      const slug =
        this.availableAgents.find(
          (item) => item.label === provider.agent_data.name
        )?.value ?? null;

      if (slug) {
        this.form.patchValue({
          [slug]: provider.register,
        });
      }
    }
  }

  cancelAddAgent() {
    this.showForm = false;
    this.newAgentForm.reset();
  }

  async getAgentOptions(agent) {
    try {
      const res = await this.api.get({
        route: 'api/v2/registration/agents-drop/',
        token: true,
        params: {
          agent__name: agent.label,
          approval__approved: true,
        },
      });

      return res;
    } catch (error) {
      return [];
    }
  }

  async handleGetAgentOptions() {
    try {
      const responses = await Promise.all(
        this.availableAgents.map((_agent) => this.getAgentOptions(_agent))
      );

      const newOptions = {
        ...this.options,
      };

      responses.forEach((_response, _index) => {
        const selectOptions = _response.map((_user) => {
          //   let name = _user.person ? _user.person.full_name : '';

          //   try {
          //     if (_user.type === 'PJ' || _user.type === 'pj') {
          //       name = `${_user.company.corporate_name}`;
          //     }
          //   } catch (e) {
          //     console.log(e);
          //   }

          //   let document = 'Documento não cadastrado';

          //   try {
          //     const document_number =
          //       _user.type == 'PF'
          //         ? _user.person.document.number
          //         : _user.company.document.number;

          //     if (document_number) {
          //       document = this.formatDocument(document_number, _user.type);
          //     }
          //   } catch (error) {
          //     console.log(error);
          //   }

          return {
            label: `${this.formatDocument(_user.document_number)} | ${
              _user.name
            }`,
            value: _user.id,
          };
        });

        const slug = this.availableAgents[_index].value;

        newOptions[slug] = selectOptions;
      });

      this.options = newOptions;

      this.setData(this.fundService.data);
    } catch (e) {
      console.error('Error: ', e);
    }
  }

  formatDocument(documentNumber: string) {
    const type = documentNumber.length === 11 ? 'CPF' : 'CNPJ';

    if (documentNumber) {
      if (type === 'CNPJ') {
        return documentNumber.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          '$1.$2.$3/$4-$5'
        );
      } else {
        return documentNumber.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
          '$1.$2.$3-$4'
        );
      }
    } else {
      return '';
    }
  }

  addNewAgent() {
    if (this.newAgentForm.valid) {
      const newAgent: ProviderValues = {
        service: this.newAgentForm.controls.function.value,
        user: this.newAgentForm.controls.function_user.value,
        fund: this.fund.id,
        serviceLabel: this.formatAgentRole(
          this.newAgentForm.controls.function.value
        ),
      };

      const alreadyHaveProvider = Object.keys(this.form.value).find((key) => {
        return (
          this.form.value[key] === newAgent.user && key === newAgent.service
        );
      });

      const alreadyHaveInAdditionalProviders = this.additionalProvidersArr.find(
        (item) => item.user === newAgent.user
      )?.service;

      if (
        alreadyHaveProvider !== undefined ||
        alreadyHaveInAdditionalProviders !== undefined
      ) {
        this.toast.show(
          'info',
          'Atenção',
          `Este usuário já foi adicionado como ${this.formatAgentRole(
            newAgent.service
          )}`
        );
        return;
      }

      this.additionalProvidersArr.push(newAgent);

      this.updateFundServiceProviders();

      this.newAgentForm.reset();
    }
  }

  updateFundServiceProviders() {
    const additionalProviders = this.additionalProvidersArr.map(
      (_function) => ({
        fund: this.fund.id,
        agent_name: _function.serviceLabel,
        register: _function.user,
      })
    );

    const fixedProviders = [
      {
        fund: null,
        agent_name: 'Administrador',
        register: this.form.controls.admin.value,
      },
      {
        fund: null,
        agent_name: 'Custodiante',
        register: this.form.controls.custodian.value,
      },
      {
        fund: null,
        agent_name: 'Controlador',
        register: this.form.controls.controller.value,
      },
      {
        fund: null,
        agent_name: 'Gestor',
        register: this.form.controls.manager.value,
      },
      {
        fund: null,
        agent_name: 'Coordenador',
        register: this.form.controls.coordinator.value,
      },
      {
        fund: null,
        agent_name: 'Agente de Cobrança',
        register: this.form.controls.agent.value,
      },
      {
        fund: null,
        agent_name: 'Consultor',
        register: this.form.controls.consultant.value,
      },
      ...additionalProviders,
    ];

    this.fundService.setFundFormData({
      ...this.fundService.data,
      service_providers: [...fixedProviders],
    });
  }

  removeAgent(index: number) {
    this.additionalProvidersArr.splice(index, 1);

    this.updateFundServiceProviders();
  }

  formatAgentRole(role: string) {
    const label = this.availableAgents.find(
      (item) => item.value === role
    )?.label;
    return label;
  }

  formatAgentName(agent: ProviderValues) {
    const userLabel = this.options[agent.service].find((item) => {
      return item.value === agent.user;
    })?.label;

    return userLabel;
  }

  handleAddAgent() {
    if (!this.showForm) {
      this.showForm = true;
      return;
    }

    this.addNewAgent();
  }
}
