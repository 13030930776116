
const gitInfo = {
  branch: "master",
  commit: "743964d",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/743964de37ec0a40790f29f214e4f12351daed80",
  date: "14/10/2024",
};

export default gitInfo;
  