import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup/signup.service';
import { MaskPipe } from 'ngx-mask';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-approval-powers',
  templateUrl: './dashboard-approval-powers.component.html',
  styleUrls: ['./dashboard-approval-powers.component.scss'],
})
export class DashboardApprovalPowersComponent implements OnInit, OnDestroy {
  private observer: MutationObserver;

  membersArr: any[] = [];
  loading = true;

  columns: TableColumnList<Groups>[] = [
    {
      name: 'Grupos',
      cell: (row) => row.name,
    },
    {
      name: 'Tipo do grupo',
      cell: (row) => row.representative_type,
    },
    {
      name: this.renderLabel(),
      cell: (row) => `<pre
      class="dash-details-card-item-title">${this.formatGroupIntegrants(
        row.members
      )}</pre>`,
      size: 2,
    },
  ];

  powerGroupColumns: TableColumnList<RelevancePowerValues>[] = [
    {
      name: 'Grupo principal',
      cell: (row) => this.formatGroup([row.main_group]),
    },
    {
      name: 'Grupo secundário',
      cell: (row) => this.formatGroup(row.secondary_group, false),
    },
    {
      name: 'Assinaturas obrigatórias',
      cell: (row) => row.required_signs_quantity,
    },
    {
      name: 'Assinaturas necessárias',
      cell: (row) => row.necessary_signs_quantity,
    },
    {
      name: 'Poderes',
      cell: (row) => this.formatPower(row.power_type),
      size: 1.5,
    },
    {
      name: '',
      cell: (row) =>
        `<div class="icon-container">
              <div class="power-details row-${row.id}">
                  <i class="fas fa-eye ml-auto"></i>
              </div>
          </div> `,
    },
  ];

  powerGroupDetails = false;
  selectedPowerGroup: Partial<RelevancePowerValues> = null;

  data: Groups[] = [];
  powerGroupData: RelevancePowerValues[] = [];
  representatives: any[] = [];

  constructor(
    private signupService: SignupService,
    private maskPipe: MaskPipe,
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.authService.approvalData.powers) {
      this.data = this.authService.approvalData.powers.register.signature_group;
      this.powerGroupData =
        this.authService.approvalData.powers.register.signature_power;
      this.representatives =
        this.authService.approvalData.powers.register.representatives;
      this.loading = false;
    } else {
      this.getApprovalData();
    }

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const item = this.el.nativeElement.querySelector('.power-details');

        if (item) {
          this.setHandlers();
        }
      });
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  async getApprovalData() {
    try {
      const req_params = {
        register: this.route.snapshot.params.id,
        tab: 'powers',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<any[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      this.authService.approvalData.powers = data[0];

      this.data = data[0].register.signature_group;
      this.powerGroupData = data[0].register.signature_power;
      this.representatives = data[0].register.representatives;
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  formatPower(power: any, isObj: boolean = false) {
    let powersArr: any[] = [];
    let authorizations = this.signupService.signatureAuthorizationChoices;

    const newPower = !isObj ? power : power.map((item) => item.id);

    if (!newPower) return '-';

    if (newPower.length > 0) {
      authorizations.map((item) => {
        if (newPower.includes(Number(item.value))) {
          powersArr.push(item.label);
        }
      });
    }

    return powersArr ? powersArr.join(', ') : '-';
  }

  formatDefaultPower(power: any) {
    // let powersArr: any[] = [];
    // let authorizations = this.signupService.signatureAuthorizationChoices;
    // if (power.length > 0) {
    //   authorizations.map((item) => {
    //     if (power.power_type[0].id.includes(Number(item.value))) {
    //       powersArr.push(item.label);
    //     }
    //   });
    // }
    // return powersArr ? powersArr.join(', ') : '';
  }

  formatGroup(groupIds: any, main_group: boolean = true) {
    let groupsArr: any[] = [];

    if (!groupIds) return '-';

    if (this.data.length > 0) {
      if (main_group) {
        const finded = this.data.find((item) => item.id === groupIds[0].id);
        return finded.name;
      }

      if (!groupIds.id) return '-';

      this.data.map((item) => {
        groupIds.id.map((i) => {
          if (i === item.id) {
            groupsArr.push(item.name);
          }
        });
      });
    }

    return groupsArr.length > 0 ? groupsArr.join(', ') : '-';
  }

  formatPowerIntegrants(membersId: number[]) {
    let usersArr: any[] = [];

    if (!membersId) return '-';

    if (this.representatives.length > 0) {
      this.representatives.map((item) => {
        if (membersId.includes(item.id)) {
          usersArr.push(
            `${item.full_name} - ${this.formatCpf(item.document_number)}`
          );
        }
      });
    }

    return usersArr ? usersArr.sort().join('\r\n') : '';
  }

  formatGroupIntegrants(membersId: number[]) {
    let usersArr: any[] = [];

    if (this.representatives.length > 0) {
      this.representatives.map((item) => {
        if (membersId.includes(item.id)) {
          usersArr.push(
            `${item.full_name} - ${this.formatCpf(item.document_number)}`
          );
        }
      });
    }

    return usersArr ? usersArr.sort().join('\r\n') : '';
  }

  setHandlers() {
    const power = document.querySelectorAll('.power-details');

    power.forEach((button) => {
      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-')[1];

        const finded = this.powerGroupData.find((item: any) => {
          if (item.id) {
            return Number(item.id) === Number(id);
          }

          return false;
        });

        if (finded) {
          this.openModal(finded);
        }
      });
    });
  }

  closeModal() {
    this.powerGroupDetails = false;
  }

  openModal(item: Partial<RelevancePowerValues>) {
    this.powerGroupDetails = true;
    this.selectedPowerGroup = item;
  }

  formatCpf(value: string) {
    const newValue = this.maskPipe.transform(value, '000.000.000-00');

    return newValue;
  }

  formatMaxValue(item) {
    if (item.indefinite_signature_value) return 'Valor Indefinido';

    if (item.max_sign_amount) {
      return Utils.formatBrl(Number(item.max_sign_amount));
    } else {
      return '-';
    }
  }

  renderLabel() {
    const assignor = this.router.url.includes('assignor');

    return assignor
      ? 'Representantes e Avalistas/Dev. Solidários'
      : 'Representantes';
  }

  //   setHistoryData() {
  //     this.data =
  //       this.approval.history.SignatureGroup ||
  //       this.approval.register.signature_group;
  //     this.powerGroupData =
  //       this.approval.history.SignaturePower ||
  //       this.approval.register.signature_power;
  //     this.representatives =
  //       this.approval.history.Representative ||
  //       this.approval.register.representatives;
  //   }
}
