import { ToastService } from './../../../services/toast/toast.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { SignupService } from 'src/app/services/signup/signup.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { MaskPipe } from 'ngx-mask';

import Utils, { GetHelper } from 'src/app/helpers/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-unified-person-powers',
  templateUrl: './signup-unified-person-powers.component.html',
  styleUrls: ['./signup-unified-person-powers.component.scss'],
})
export class SignupUnifiedPersonPowersComponent implements OnInit {
  @Input() currentTab: string;

  form = this.formBuilder.group({
    group_name: [null, [Validators.required]],
    members: [null, [Validators.required]],
  });

  editForm = this.formBuilder.group({
    group_name: [null, [Validators.required]],
    members: [null, [Validators.required]],
  });

  minValue = 0;

  loading = false;

  powerForm = this.formBuilder.group({
    power_types: [null, [Validators.required]],
    default_power_type: [null, []],
    principal_group: [null, [Validators.required]],
    secondary_group: [null, []],
    required_signatures_quantity: [
      null,
      [Validators.required, Validators.min(1)],
    ],
    required_signatures: [null, []],
    necessary_signatures: [null, []],
    necessary_signatures_quantity: [
      null,
      [Validators.required, Validators.min(1)],
    ],
    max_signature_value: [null, [Validators.min(this.minValue)]],
    min_signature_value: [null, []],
    indefinite_signature_value: [false, []],
    description: [null, []],
  });

  editPowerForm = this.formBuilder.group({
    power_types: [null, [Validators.required]],
    principal_group: [null, [Validators.required]],
    default_power_type: [null, []],
    secondary_group: [null, []],
    required_signatures_quantity: [
      null,
      [Validators.required, Validators.min(1)],
    ],
    required_signatures: [null, []],
    necessary_signatures_quantity: [
      null,
      [Validators.required, Validators.min(1)],
    ],
    necessary_signatures: [null, []],
    max_signature_value: [null, [Validators.min(this.minValue)]],
    min_signature_value: [null, []],
    edit_indefinite_signature_value: [false, []],
    description: [null, []],
  });

  removePowerModal = false;

  editModal = false;
  editPowerModal = false;

  removeModal = false;
  selectedGroup: SignatureGroupResponse | null = null;
  selectedPower: SignaturePowerResponse | null = null;

  groupIncluded = false;
  powerDetails = false;

  maxSignatures = 0;
  minSignatures = 0;

  membersLabel = '';

  columns: TableColumn<RelevanceGroupValues>[] = [];

  membersArr: SelectItem[] = [];
  groupList: SelectItem[] = [];
  secondaryGroupList: SelectItem[] = [];
  filteredAuthorizationChoices: SelectItem[] = [];
  selectedAuthorizations: SelectItem[] = [];
  groupType: SelectItem[] = [
    { label: 'Representante', value: 'representative' },
    { label: 'Avalista', value: 'guarantor' },
    { label: 'Devedor Solidário', value: 'solidarity_debtor' },
  ];

  groupsData = [];
  powersData = [];
  groupPastValue = [];

  sendingGroup = false;
  sendingPower = false;

  filteredIntegrants: SelectItem[] = [];

  showGroupEditError = false;

  isAssignor = false;
  representativeData: RepresentativeResponse[] = [];

  recentlyOpen = false;
  powerRecentlyOpen = false;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private router: Router,
    private api: ApiService,
    private toast: ToastService,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    this.isAssignor = this.router.url.includes('assignor');

    this.powerForm.statusChanges.subscribe((status) => {
      Utils.getErrors(this.powerForm);
    });

    this.getData();

    this.form.controls.members.updateValueAndValidity();
    this.editForm.controls.members.updateValueAndValidity();

    this.form.controls.members.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value && value.length > 0) {
          const isExcluding = this.groupPastValue.length > value.length;

          this.bondSpouse(value, isExcluding);
        }
      });

    this.editForm.controls.members.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value && value.length > 0) {
          const isExcluding = this.groupPastValue.length > value.length;

          this.bondSpouse(value, isExcluding, true);
        }
      });

    this.powerForm.controls.required_signatures.setValidators([
      this.verifyLengthArray('min', this.maxSignatures),
    ]);

    this.powerForm.controls.necessary_signatures.setValidators([
      this.verifyLengthArray('min', this.minSignatures),
    ]);

    this.powerForm.controls.required_signatures.updateValueAndValidity();
    this.powerForm.controls.necessary_signatures.updateValueAndValidity();

    this.editPowerForm.controls.required_signatures.setValidators([
      this.verifyLengthArray('min', this.maxSignatures),
    ]);

    this.editPowerForm.controls.necessary_signatures.setValidators([
      this.verifyLengthArray('min', this.minSignatures),
    ]);

    this.editPowerForm.controls.required_signatures.updateValueAndValidity();
    this.editPowerForm.controls.necessary_signatures.updateValueAndValidity();

    this.signupService.complementaryDataFetchedEvent.emit();

    this.signupService.representativeAddedEvent.subscribe(() => {
      this.getRepresentatives();
    });

    this.powerForm.controls.principal_group.valueChanges.subscribe((value) => {
      const type = this.groupList.find((item) => item.value === value)?.extra;

      if (value) {
        this.secondaryGroupList = this.groupList.filter((item: any) => {
          return item.extra === type && item.value !== value;
        });
      }

      this.setFilteredIntegrants();
      this.setFilteredAuthorizationChoices();

      this.powerForm.controls.secondary_group.setValue(null);
      this.powerForm.controls.necessary_signatures.setValue(null);
      this.powerForm.controls.required_signatures.setValue(null);
      this.powerForm.controls.power_types.setValue(null);
    });

    this.powerForm.controls.secondary_group.valueChanges.subscribe((value) => {
      this.setFilteredIntegrants();
      this.powerForm.controls.necessary_signatures.setValue(null);
      this.powerForm.controls.required_signatures.setValue(null);
    });

    this.editPowerForm.controls.principal_group.valueChanges.subscribe(
      (value) => {
        if (value) {
          const type = this.groupList.find(
            (item) => item.value === value
          )?.extra;

          this.secondaryGroupList = this.groupList.filter((item: any) => {
            return item.value === type;
          });

          this.setFilteredIntegrants(true);
          this.setFilteredAuthorizationChoices(true);
        }
        this.editPowerForm.controls.secondary_group.setValue(null);
        this.editPowerForm.controls.necessary_signatures.setValue(null);
        this.editPowerForm.controls.required_signatures.setValue(null);

        if (!this.powerRecentlyOpen) {
          this.editPowerForm.controls.power_types.setValue(null);
        }

        this.powerRecentlyOpen = false;
      }
    );

    this.editPowerForm.controls.secondary_group.valueChanges.subscribe(
      (value) => {
        this.setFilteredIntegrants(true);

        this.editPowerForm.controls.necessary_signatures.setValue(null);
        this.editPowerForm.controls.required_signatures.setValue(null);
      }
    );

    this.powerForm.controls.necessary_signatures_quantity.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        const valueNumber = Number(Utils.onlyNumbers(value));
        this.minSignatures = valueNumber;

        if (valueNumber === 0) {
          this.powerForm.controls.necessary_signatures.setValue(null);
          this.powerForm.controls.necessary_signatures.setValidators([]);
        } else {
          this.changeLengthVerification(
            this.powerForm.controls.necessary_signatures.value,
            false
          );
        }

        this.powerForm.controls.necessary_signatures.updateValueAndValidity();
      });

    this.powerForm.controls.required_signatures_quantity.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        const valueNumber = Number(Utils.onlyNumbers(value));

        this.maxSignatures = valueNumber;

        if (valueNumber === 0) {
          this.powerForm.controls.required_signatures.setValue(null);
          this.powerForm.controls.required_signatures.setValidators([]);
        } else {
          this.changeLengthVerification(
            this.powerForm.controls.required_signatures.value,
            true
          );
        }

        this.powerForm.controls.required_signatures.updateValueAndValidity();
      });

    this.editPowerForm.controls.necessary_signatures_quantity.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        const valueNumber = Number(Utils.onlyNumbers(value));

        this.minSignatures = valueNumber;

        if (valueNumber === 0) {
          this.editPowerForm.controls.necessary_signatures.setValue(null);
          this.editPowerForm.controls.necessary_signatures.setValidators([]);
        } else {
          this.changeLengthVerification(
            this.editPowerForm.controls.necessary_signatures.value,
            false,
            true
          );
        }

        this.editPowerForm.controls.necessary_signatures.updateValueAndValidity();
      });

    this.editPowerForm.controls.required_signatures_quantity.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        const valueNumber = Number(Utils.onlyNumbers(value));

        this.maxSignatures = valueNumber;

        if (valueNumber === 0) {
          this.editPowerForm.controls.required_signatures.setValue(null);
          this.editPowerForm.controls.required_signatures.setValidators([]);
        } else {
          this.changeLengthVerification(
            this.editPowerForm.controls.required_signatures.value,
            true,
            true
          );
        }

        this.editPowerForm.controls.required_signatures.updateValueAndValidity();
      });

    this.powerForm.controls.indefinite_signature_value.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.powerForm.controls.max_signature_value.setValue(null);
        } else {
          this.minValue = this.powerForm.controls.min_signature_value.value;
          this.powerForm.controls.max_signature_value.setValidators([
            Validators.min(this.minValue),
          ]);
          this.powerForm.controls.max_signature_value.updateValueAndValidity();
        }
      }
    );

    this.editPowerForm.controls.edit_indefinite_signature_value.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.editPowerForm.controls.max_signature_value.setValue(null);
        } else {
          this.minValue = this.editPowerForm.controls.min_signature_value.value;
          this.editPowerForm.controls.max_signature_value.setValidators([
            Validators.min(this.minValue),
          ]);
          this.editPowerForm.controls.max_signature_value.updateValueAndValidity();
        }
      }
    );

    this.powerForm.controls.min_signature_value.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        this.minValue = value;

        this.powerForm.controls.max_signature_value.setValidators([
          Validators.min(this.minValue),
        ]);
        this.powerForm.controls.max_signature_value.updateValueAndValidity();
      });

    this.editPowerForm.controls.min_signature_value.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        this.minValue = value;

        this.editPowerForm.controls.max_signature_value.setValidators([
          Validators.min(this.minValue),
        ]);
        this.editPowerForm.controls.max_signature_value.updateValueAndValidity();
      });

    this.powerForm.controls.necessary_signatures.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value && value.length > 0) {
          this.changeLengthVerification(value, false);
        }
      });

    this.editPowerForm.controls.necessary_signatures.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value && value.length > 0) {
          this.changeLengthVerification(value, false, true);
        }
      });

    this.powerForm.controls.required_signatures.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value && value.length > 0) {
          this.changeLengthVerification(value, true);
        }
      });

    this.editPowerForm.controls.required_signatures.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value && value.length > 0) {
          this.changeLengthVerification(value, true, true);
        }
      });

    this.powerForm.controls.power_types.valueChanges.subscribe((value) => {
      if (value && value.length > 0) {
        this.selectedAuthorizations = this.filteredAuthorizationChoices.filter(
          (item) => value.includes(item.value)
        );
      } else {
        this.selectedAuthorizations = [];
        this.powerForm.controls.default_power_type.setValue(null);
      }
    });

    this.editPowerForm.controls.power_types.valueChanges.subscribe((value) => {
      if (value && value.length > 0) {
        this.selectedAuthorizations = this.filteredAuthorizationChoices.filter(
          (item) => value.includes(item.value)
        );
      } else {
        if (!this.powerRecentlyOpen) {
          this.selectedAuthorizations = [];
          this.editPowerForm.controls.default_power_type.setValue(null);
        }

        this.powerRecentlyOpen = false;
      }
    });
  }

  async getData() {
    this.loading = true;
    this.getRepresentatives();
  }

  verifyLengthArray = (lengthVerify: string, arrayLength: number) => {
    return (c: AbstractControl): { [key: string]: any } => {
      const verify =
        lengthVerify === 'max'
          ? c.value && c.value.length <= arrayLength
          : c.value && c.value.length >= arrayLength;

      if (verify) return null;

      return { VerifyLengthArray: true };
    };
  };

  formatRequiredSignatures(row: SignaturePowerResponse) {
    const required = row.required_signs
      .map(
        (item) => this.formatCpf(item.document_number) + ' | ' + item.full_name
      )
      .sort()
      .join('\r\n');
    return required;
  }

  formatNecessarySignatures(row: SignaturePowerResponse) {
    const necessary = row.necessary_signs
      .map(
        (item) => this.formatCpf(item.document_number) + ' | ' + item.full_name
      )
      .sort()
      .join('\r\n');
    return necessary;
  }

  formatSignatures(row: SignaturePowerResponse) {
    const necessary = row.necessary_signs
      .map(
        (item) => this.formatCpf(item.document_number) + ' | ' + item.full_name
      )
      .join(', ');
    const required =
      row.required_signs_quantity ?? row.necessary_signs_quantity;

    return `${necessary ?? '0'} / ${required ?? '0'}`;
  }

  primaryGroupLabel(group: string) {
    const primaryGroup = this.groupList.find(
      (item: any) => item.value === group
    );

    return primaryGroup?.label;
  }

  secondaryGroupLabel(group: string[]) {
    const secondaryGroup = group.map((item) => {
      return this.groupList.find((_) => _.value === item)?.label;
    });

    return secondaryGroup.join(', ');
  }

  formatGroup(row: SignaturePowerResponse) {
    const formattedSecondaryGroup = row.secondary_group
      .map((group) => group.name)
      .join(', ');

    return formattedSecondaryGroup;
  }

  formatPowers(values: Powertype[]) {
    const powers = values.map((item) => item.name);

    return powers.join(', ');
  }

  async getRepresentatives() {
    try {
      const { data } = await this.api.get<
        ApiResponse<RepresentativeResponse[]>
      >({
        route: 'api/registration/representative/',
        token: true,
      });

      const mappedRepresentatives = data.map((item) => ({
        label: this.formatCpf(item.document_number) + ' | ' + item.full_name,
        value: String(item.id),
        extra: item.marital_id ? String(item.marital_id) : null,
      }));

      this.representativeData = data;

      this.membersArr = mappedRepresentatives;

      await this.getGroupsDataAndFillForm();
      this.getPowersDataAndFillForm();
    } catch (error) {
      this.toast.show('error', 'Erro!', error.error.message);
    }
  }

  formatIntegrants(members: RepresentativeResponse[]) {
    return members
      .map(
        (item) =>
          this.formatCpf(item.document_number) +
          ' | ' +
          item.full_name +
          this.showSpouse(item)
      )
      .sort()
      .join('\r\n');
  }

  getDisabled() {
    return this.form.invalid;
  }

  getGroupDisabled() {
    return (
      this.powerForm.controls.principal_group.value === '' ||
      this.powerForm.controls.principal_group.value === null
    );
  }

  getEditGroupDisabled() {
    return (
      this.editPowerForm.controls.principal_group.value === '' ||
      this.editPowerForm.controls.principal_group.value === null
    );
  }

  handleOpenGroupEdit(item: SignatureGroupResponse) {
    const isMainGroup = this.powersData.find((_item) => {
      return _item.main_group.id === item.id;
    });

    const isSecondaryGroup = this.powersData.find((power) =>
      power.secondary_group.find((group) => group.id === item.id)
    );

    if (isMainGroup || isSecondaryGroup) {
      this.showGroupEditError = true;
      return;
    }
    this.recentlyOpen = true;

    this.editModal = true;
    this.selectedGroup = item;

    this.editForm.patchValue({
      group_name: item.name,
      members: item.members.map((item) => String(item.id)),
    });
  }

  handleOpenPowerDetails(item: SignaturePowerResponse) {
    this.powerDetails = true;
    this.selectedPower = item;
  }

  handleOpenPowerEdit(item: SignaturePowerResponse) {
    this.powerRecentlyOpen = true;
    this.editPowerModal = true;
    this.selectedPower = item;

    const values = {
      principal_group: item.main_group.id,
      secondary_group: item.secondary_group.map((item) => item.id),
      required_signatures_quantity: item.required_signs_quantity,
      required_signatures: item.required_signs.map((item) => item.id),
      necessary_signatures: item.necessary_signs.map((item) => item.id),
      necessary_signatures_quantity: item.necessary_signs_quantity,
      max_signature_value: item.max_sign_amount,
      min_signature_value: item.min_sign_amount,
      power_types: item.power_type.map((item) => String(item.id)),
      edit_indefinite_signature_value: item.indefinite_signature_value,
      description: item.description,
      default_power_type:
        item.default_power_type.length > 0
          ? item.default_power_type[0].power_type.map((item) => String(item.id))
          : null,
    };

    this.editPowerForm.patchValue(values);
  }

  handleOpenPowerWarningModal(item: SignaturePowerResponse) {
    this.selectedPower = item;
    this.removePowerModal = true;
  }

  handleOpenWarningModal(item: SignatureGroupResponse) {
    this.selectedGroup = item;

    const secondaryGroups: number[] = [];

    const primaryGroups = this.powersData.map((item) => item.main_group.id);
    this.powersData.forEach((item) =>
      item.secondary_group.forEach((item) => secondaryGroups.push(item.id))
    );

    const ids = [...primaryGroups, ...secondaryGroups];

    if (ids.includes(item.id)) {
      this.groupIncluded = true;
    }

    this.removeModal = true;
  }

  closeModal() {
    this.editModal = false;
    this.removeModal = false;
    this.selectedGroup = null;
    this.groupIncluded = false;
    this.selectedPower = null;
    this.editPowerModal = false;
    this.powerDetails = false;
    this.removePowerModal = false;
    this.showGroupEditError = false;
    this.editForm.reset();
    this.editPowerForm.reset();
  }

  renderTitle(selectedGroup: Partial<RelevanceGroupValues>) {
    return `Editar grupo ${selectedGroup.group_name}`;
  }

  async removeGroupData() {
    try {
      await this.api.delete<ApiResponse<null>>({
        route: `api/registration/signature_group/${this.selectedGroup.id}/`,
        token: true,
      });

      this.closeModal();

      this.toast.show('info', 'Sucesso', 'Grupo removido com sucesso!');

      this.getGroupsDataAndFillForm();
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  async removePowerData() {
    try {
      await this.api.delete<ApiResponse<null>>({
        route: `api/registration/signature_power/${this.selectedPower.id}/`,
        token: true,
      });

      this.closeModal();

      this.toast.show('info', 'Sucesso', 'Poder removido com sucesso!');

      this.getPowersDataAndFillForm();
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  getPowerFormDisabled() {
    return this.groupsData.length === 0;
  }

  async getGroupsDataAndFillForm() {
    try {
      const { data } = await this.api.get<ApiResponse<any[]>>({
        route: 'api/registration/signature_group/',
        token: true,
      });

      this.groupsData = data;

      this.groupList = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      this.powerForm.reset();
      this.editPowerForm.reset();

      this.signupService.setPersonFormStatus(
        'powers',
        this.validPowersStatus()
      );
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
    this.loading = false;
  }

  async sendGroupData() {
    this.sendingGroup = true;
    try {
      const valuesHelper = this.selectedGroup
        ? new GetHelper(this.editForm.value)
        : new GetHelper(this.form.value);

      const payload = {
        name: valuesHelper.get('group_name'),
        members: valuesHelper.get('members'),
        representative_type: 'representative',
      };

      console.table(valuesHelper.getRemainingObject());

      const response = this.selectedGroup
        ? await this.api.put<ApiResponse<any>>({
            route: `api/registration/signature_group/${this.selectedGroup.id}/`,
            token: true,
            body: payload,
          })
        : await this.api.post<ApiResponse<any>>({
            route: 'api/registration/signature_group/',
            token: true,
            body: payload,
          });

      this.toast.show('info', 'Sucesso', response.message);

      this.form.reset();
      this.closeModal();

      this.getGroupsDataAndFillForm();
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
      return undefined;
    }
    this.sendingGroup = false;
  }

  async getPowersDataAndFillForm() {
    try {
      const { data } = await this.api.get<ApiResponse<any[]>>({
        route: 'api/registration/signature_power/',
        token: true,
      });

      this.powersData = data;

      this.validPowersStatus();
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
  }

  async sendPowerData() {
    this.sendingPower = true;
    try {
      const valuesHelper = this.selectedPower
        ? new GetHelper(this.editPowerForm.value)
        : new GetHelper(this.powerForm.value);

      const indefinite_signature_value = this.selectedPower
        ? valuesHelper.get('edit_indefinite_signature_value')
        : valuesHelper.get('indefinite_signature_value');

      const payload = {
        power_type: valuesHelper.get('power_types'),
        main_group: valuesHelper.get('principal_group'),
        secondary_group: valuesHelper.get('secondary_group') ?? [],
        required_signs_quantity: valuesHelper.get(
          'required_signatures_quantity'
        ),
        required_signs: valuesHelper.get('required_signatures'),

        necessary_signs_quantity: valuesHelper.get(
          'necessary_signatures_quantity'
        ),
        necessary_signs: valuesHelper.get('necessary_signatures'),
        min_sign_amount: valuesHelper.get('min_signature_value'),
        max_sign_amount: valuesHelper.get('max_signature_value'),
        indefinite_signature_value: indefinite_signature_value ?? false,
        description: valuesHelper.get('description'),
        default_power_type: valuesHelper.get('default_power_type'),
      };

      console.table(valuesHelper.getRemainingObject());

      const response = this.selectedPower
        ? await this.api.put<ApiResponse<any>>({
            route: `api/registration/signature_power/${this.selectedPower.id}/`,
            token: true,
            body: payload,
          })
        : await this.api.post<ApiResponse<any>>({
            route: 'api/registration/signature_power/',
            token: true,
            body: payload,
          });

      this.toast.show('info', 'Sucesso', response.message);

      this.powerForm.reset();
      this.closeModal();

      this.getPowersDataAndFillForm();
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sendingPower = false;
  }

  formatBrl(amount: number | string) {
    const formatted = Utils.formatBrl(Number(amount));

    return formatted;
  }

  formatCpf(value: string) {
    const newValue = this.maskPipe.transform(value, '000.000.000-00');

    return newValue;
  }

  validPowersStatus() {
    let isValid = true;

    if (this.groupsData.length > 0) {
      isValid = this.powersData.length > 0;
    }

    this.signupService.setPersonFormStatus('powers', isValid);

    return isValid;
  }

  setFilteredIntegrants(isEditForm: boolean = false) {
    const primaryGroupId = isEditForm
      ? this.editPowerForm.controls.principal_group.value
      : this.powerForm.controls.principal_group.value;

    if (!primaryGroupId) {
      this.filteredIntegrants = [];

      return;
    }

    const selectedPrimaryGroup = this.groupsData.find(
      (group) => group.id === primaryGroupId
    );

    const primaryFiltered = selectedPrimaryGroup.members.map((item) => {
      const obj = {
        label: this.formatCpf(item.document_number) + ' | ' + item.full_name,
        value: item.id,
      };

      return obj;
    });

    const secondaryGroupIds = isEditForm
      ? this.editPowerForm.controls.secondary_group.value
      : this.powerForm.controls.secondary_group.value;

    const secondaryGroups = secondaryGroupIds
      ? secondaryGroupIds.map((item) => {
          return this.groupsData.find((group) => group.id === item);
        })
      : [];

    const secondaryFilteredIntegrants = secondaryGroups.reduce((prev, item) => {
      const membersArr = item.members.map((i) => ({
        label: this.formatCpf(i.document_number) + ' | ' + i.full_name,
        value: i.id,
      }));

      return prev.concat(membersArr);
    }, []);

    const newFilteredIntegrants = [
      ...primaryFiltered,
      ...secondaryFilteredIntegrants,
    ].filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

    this.filteredIntegrants = newFilteredIntegrants;
  }

  showSpouse(item: RepresentativeResponse) {
    const isntRepresentative = item.solidarity_debtor || item.guarantor;
    const spouse = item.spouse;
    const spousal_consent = item.spousal_consent;
    let spouseLabel = '';

    if (isntRepresentative && spouse && spousal_consent) {
      spouseLabel = `,  ${spouse.name} - ${this.formatCpf(spouse.document)}`;
    }

    return spouseLabel;
  }

  handleSetSigns() {}

  bondSpouse(
    members: string[],
    excluding: boolean,
    isEditing: boolean = false
  ) {
    let arr = members;

    if (!excluding) {
      members.forEach((item) => {
        const member = this.membersArr.find((i) => i.value === item);

        if (!members.includes(member.value)) {
          arr.push(member.value);
        }

        if (member.extra !== null && !members.includes(member.extra)) {
          arr.push(member.extra);
        }
      });
    }

    this.groupPastValue = arr;
    const allSpousesAreIncluded = arr.every((item) => {
      const member = this.membersArr.find((i) => i.value === item);

      return member.extra === null || arr.includes(member.extra);
    });

    if (isEditing) {
      this.editForm.controls.members.setValue(arr);
      this.editForm.controls.members.updateValueAndValidity();
      this.editForm.controls.members.setErrors(
        allSpousesAreIncluded ? null : { spouse: true }
      );
    } else {
      this.form.controls.members.setValue(arr);
      this.form.controls.members.updateValueAndValidity();
      this.form.controls.members.setErrors(
        allSpousesAreIncluded ? null : { spouse: true }
      );
    }
  }

  changeLengthVerification(
    signs: number[],
    requiredSigns: boolean,
    isEditForm: boolean = false
  ) {
    let count = 0;
    const minValue = this.minSignatures;
    const maxValue = this.maxSignatures;

    if (signs && signs.length > 0) {
      signs.forEach((item) => {
        const member = this.representativeData.find((i) => i.id === item);
        const isntRepresentative = member.solidarity_debtor || member.guarantor;
        const spousal_consent = member.spousal_consent;

        if (isntRepresentative && spousal_consent) {
          count++;
        }
      });
    }

    if (requiredSigns) {
      if (isEditForm) {
        this.editPowerForm.controls.required_signatures.setValidators([
          Validators.required,
          this.verifyLengthArray('min', maxValue - count),
        ]);
      } else {
        this.powerForm.controls.required_signatures.setValidators([
          Validators.required,
          this.verifyLengthArray('min', maxValue - count),
        ]);
      }
    } else {
      if (isEditForm) {
        this.editPowerForm.controls.necessary_signatures.setValidators([
          Validators.required,
          this.verifyLengthArray('min', minValue - count),
        ]);
      } else {
        this.powerForm.controls.necessary_signatures.setValidators([
          Validators.required,
          this.verifyLengthArray('min', minValue - count),
        ]);
      }
    }

    this.powerForm.controls.necessary_signatures.updateValueAndValidity();
    this.editPowerForm.controls.necessary_signatures.updateValueAndValidity();
    this.powerForm.controls.required_signatures.updateValueAndValidity();
    this.editPowerForm.controls.required_signatures.updateValueAndValidity();
  }

  setFilteredAuthorizationChoices(isEditForm: boolean = false) {
    const value = isEditForm
      ? this.editPowerForm.controls.principal_group.value
      : this.powerForm.controls.principal_group.value;

    const group = this.groupList.find((item) => item.value === value);

    this.filteredAuthorizationChoices =
      this.signupService.signatureAuthorizationChoices.filter((item) => {
        if (item.extra === null) return true;

        return item?.extra === group?.extra;
      });
  }
}
