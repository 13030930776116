<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{
                        height: '468px',
                        margin:'0  0 24px'
                    }">
            </ngx-skeleton-loader>

            <ngx-skeleton-loader [theme]="{
                        height: '468px',
                        margin:'0'
                      }">
            </ngx-skeleton-loader>
        </div>

        <div class="col-8">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>

            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0'
                      }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12 col-lg-4">
        <div class="dash-details-card">
            <div class="dash-details-card-header">
                <span>Dados básicos</span>

                <ng-container *ngIf="filteredFiles.basic_info">
                    <app-attachment-button [slugArr]="filteredFiles.basic_info"
                        (onSelect)="handleSelect($event,'basic_info')">
                    </app-attachment-button>
                </ng-container>
            </div>
            <app-spacer [bottom]="15"></app-spacer>

            <ng-container *ngIf="approval.register.type === 'PJ' && isFund">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.company.document?.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.brand_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome Fantasia
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.brand_name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.foundation_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data da fundação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.foundation_date
                                    | date: 'dd/MM/yyyy' ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.branch">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Forma de constituição
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.branch_display}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.framework">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.framework.name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.giin">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    GIIN
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.giin}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.contract_type_display">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de contrato
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.contract_type_display}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone.number)
                                        ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.phone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone.number)
                                        ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.cnae">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae.name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.net_worth">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Patrimônio líquido
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.net_worth)
                                    ??
                                    '-'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>


            <ng-container
                *ngIf="approval.register.type === 'PJ' && isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 col-lg-6"
                            *ngIf="approval.register.company.document?.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.brand_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome Fantasia
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.brand_name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.foundation_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.foundation_date
                                    |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.cnae">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae.name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.capital">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    capital social
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.capital ?
                                    formatBrl(approval.register.company.capital)
                                    : '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.yearly_income">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    faturamento bruto anual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.yearly_income)
                                    ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.number_employees">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?
                                    getEmployeesName(approval.register.company.number_employees)
                                    :
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="(approval.register.type === 'PJ' && role === 'assignor' || approval.register.type === 'PJ' && role === 'shareholder') && !isSimplified && !isFund">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.company.document?.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    : '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.brand_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome Fantasia
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.brand_name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.foundation_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.foundation_date
                                    |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.branch">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Forma de constituição
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.branch_display}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.framework">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.framework.name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.municipal_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Inscrição municipal
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.municipal_registration
                                    ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Inscrição estadual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.state_registration
                                    ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.free_state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Isento de inscrição estadual
                                </span>

                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.nire">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NIRE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nire ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.giin && role === 'assignor'">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-subtitle">
                                    GIIN
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.giin ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de contrato
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{renderContractType(approval.register.company.contract_type)}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone.number)
                                        ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.phone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone.number)
                                        ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.contact_email">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Email
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company.contact_email"
                                        target="_blank">
                                        {{approval.register.company.contact_email}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.company.site"
                                        target="_blank">
                                        {{approval.register.company.site}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.cnae">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae.name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.company_size">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    PORTE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.company_size_display}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.number_employees">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?
                                    getEmployeesName(approval.register.company.number_employees)
                                    :
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.yearly_income">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    faturamento bruto anual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.yearly_income)
                                    ?? '-'}}
                                </span>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.capital">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    capital social
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.capital)
                                    ??
                                    '-'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="divider"></div>
                <app-spacer [bottom]="30"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.company.assignor?.joint_obligation">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    coobrigação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor.joint_obligation
                                    === "true"
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.assignor?.relationship_with_other_institutions">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    RELACIONAMENTO COM OUTRAS INSTITUIÇÕES
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor.relationship_institution}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12"
                            *ngIf="approval.register.company.judicial_recovery">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Em recuperação judicial
                                </span>

                                <span class="dash-details-card-item-title">
                                    Sim
                                    <ng-container
                                        *ngIf="approval.register.company.judicial_recovery_date">
                                        ,
                                        {{approval.register.company.judicial_recovery_date
                                        | date: 'dd/MM/yyyy'}}
                                    </ng-container>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.billet_emitter">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Emite boleto
                                </span>

                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12"
                            *ngIf="approval.register.company.nfe_emitter">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Emite NFe
                                </span>

                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12"
                            *ngIf="approval.register.company.duplicate_emitter">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Emite duplicata
                                </span>

                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <!-- <div class="col-12" *ngIf="approval.register.company.judicial_recovery">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.judicial_recovery_date | date: 'dd/MM/yyyy' ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data da homologação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor.authorization_bacen_queries ? 'Sim' : 'Não'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Autoriza {{'brand.name' | theme}} efetuar consulta no BACEN
                                </span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PJ' && role === 'provider'">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 col-lg-6"
                            *ngIf="approval.register.company.document?.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.brand_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome Fantasia
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.brand_name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.foundation_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.foundation_date
                                    |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.branch">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Forma de constituição
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.branch_display}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.framework">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.framework.name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.municipal_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Inscrição municipal
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.municipal_registration
                                    ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Inscrição estadual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.state_registration
                                    ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.free_state_registration">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Isento de inscrição estadual
                                </span>

                                <span class="dash-details-card-item-title">
                                    Sim
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.nire">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NIRE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nire ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de contrato
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{renderContractType(approval.register.company.contract_type)}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone.number)
                                        ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.phone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone.number)
                                        ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <!-- <div class="col-12 col-md-6" *ngIf="approval.phones.length > 1">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.contact_email" target="_blank">
                                        {{formatPhone(approval.phones[0].number) ?? '-'}}
                                    </a>
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <div class="col-12"
                            *ngIf="approval.register.company.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company.contact_email"
                                        target="_blank">
                                        {{approval.register.company.site ??
                                        '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.company.cnae">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae.name}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.company.number_employees">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?
                                    getEmployeesName(approval.register.company.number_employees)
                                    :
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && role !== 'provider' &&  !isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.person.document.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatCpf(approval.register.person.document)}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.birth_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.naturalness">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NATURALIDADE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.naturalness ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.gender">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '-'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.marital_status">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('marital_choices',approval.register.person.marital_status)?.label
                                    ?? '-'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <ng-container *ngIf="approval.register.person.spouse ">
                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CPF DO CÔNJUGE
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.spouse.document
                                        ?? '-'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        NOME DO CÔNJUGE
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.spouse.name
                                        ?? '-'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        REGIME DE CASAMENTO
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{getChoicesName('marital_statutes',approval.register.person.spouse.marriage_bond)?.label
                                        ?? '-'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.mother_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.mother_name ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.father_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.father_name ??
                                    '-'}}

                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de documento
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.type.name}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Número do documento
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.number}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Órgão expedidor
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.uf}}
                                        /{{approval.register.person.secondary_document.dispatching_agency}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de expedição
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.expedition_date
                                        |
                                        date: 'dd/MM/yyyy' ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.person.cellphone.number)}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.phone">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.phone.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.person.phone.number)}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.email">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Email
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.person.email"
                                        target="_blank">
                                        {{approval.register.person.email}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.person.site"
                                        target="_blank">
                                        {{approval.register.person.site ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.revenues">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Rendimento dos últimos 12 meses
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.person.revenues)
                                    ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container
                    *ngIf="approval.register.person.assignor?.relationship_with_other_institutions">
                    <div class="divider"></div>
                    <app-spacer [bottom]="30"></app-spacer>

                    <div class="dash-details-card-body">
                        <div class="row">
                            <div class="col-12"
                                *ngIf="approval.register.person.assignor?.relationship_with_other_institutions">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        RELACIONAMENTO COM OUTRAS INSTITUIÇÕES
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.assignor.relationship_institution}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && role === 'provider' &&  !isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.person.document.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.number
                                    ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.birth_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.naturalness">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NATURALIDADE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.naturalness ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE EXPEDIÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.expedition_date
                                    | date:'dd/MM/yyyy'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.gender">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '-'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.marital_status">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('marital_choices',approval.register.person.marital_status)?.label
                                    ?? '-'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <ng-container *ngIf="approval.register.person.spouse">
                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CPF DO CÔNJUGE
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.spouse.document
                                        ?? '-'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        NOME DO CÔNJUGE
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{approval.register.person.spouse.name
                                        ?? '-'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        REGIME DE CASAMENTO
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{getChoicesName('marital_statutes',approval.register.person.spouse.marriage_bond)?.label
                                        ?? '-'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.mother_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.mother_name ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.father_name">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.father_name ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de documento
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.type.name}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.cellphone">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Número do documento
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.number}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Órgão expedidor
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.uf}}
                                        /{{approval.register.person.secondary_document.dispatching_agency}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.cellphone">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de expedição
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone.number"
                                        target="_blank">
                                        {{approval.register.person.secondary_document.expedition_date
                                        |
                                        date: 'dd/MM/yyyy' ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.site">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.person.site"
                                        target="_blank">
                                        {{approval.register.person.site ?? '-'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12"
                            *ngIf="approval.register.person.revenues">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Faturamento
                                    nos últimos 12 meses
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.person.revenues)
                                    ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12"
                            *ngIf="approval.register.person.document.number">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.number
                                    ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.birth_date">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.naturalness">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NATURALIDADE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.naturalness ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DOCUMENTO
                                </span>

                                <span class="dash-details-card-item-title">

                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.document">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE EXPEDIÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.expedition_date
                                    | date:'dd/MM/yyyy'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="approval.register.person.gender">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '-'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                        <!--
                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('marital_choices',
                                    approval.general.marital_status)?.label}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <ng-container
                            *ngIf="approval.general.marital_status === 2 || approval.general.marital_status === 3 ">
                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '-'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        CPF DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '-'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        NOME DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-12">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '-'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        REGIME DE CASAMENTO
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.general.mother_name ?? '-'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.general.father_name ?? '-'}}

                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->
                    </div>
                </div>
            </ng-container>
        </div>

        <app-spacer [bottom]="24"></app-spacer>

        <ng-container *ngIf="!isSimplified && approval.register.address">
            <div class="dash-details-card">
                <div class="dash-details-card-header">
                    <span>Endereço</span>

                    <ng-container *ngIf="filteredFiles.address">
                        <app-attachment-button [slugArr]="filteredFiles.address"
                            (onSelect)="handleSelect($event,'address')">
                        </app-attachment-button>
                    </ng-container>
                </div>
                <app-spacer [bottom]="24"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                CEP
                            </span>

                            <span class="dash-details-card-item-title">
                                {{approval.register.address.cep ?? '-'}}
                            </span>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                ENDEREÇO COMPLETO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{approval.register.address.public_place ??
                                '-'}},
                                {{approval.register.address.number?? '-'}},
                                {{approval.register.address.complement?? '-'}}
                                <br>
                                {{approval.register.address.district?? '-'}},
                                {{approval.register.address.city?? '-'}} -
                                {{approval.register.address.uf?? '-'}}<br>
                                Brasil
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <iframe [src]="approval.map_address" width="100%" height="200" frameborder="0" style="border:0;"
                allowfullscreen></iframe> -->
            </div>
        </ng-container>

        <ng-container *ngIf="isFund">
            <div class="dash-details-card"
                *ngIf="approval.register.company.contact_email">
                <div class="dash-details-card-header">
                    <span>E-mails de contato do fundo</span>
                </div>
                <app-spacer [bottom]="24"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                Contato principal
                            </span>

                            <span class="dash-details-card-item-title">
                                {{approval.register.company.contact_email}}
                            </span>
                        </div>
                    </div>
                    <ng-container
                        *ngIf="approval.register.company.secondary_email">
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-12 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Contato secundário
                                </span>

                                <span class="dash-details-card-item-title"
                                    *ngFor="let email of approval.register.company.secondary_email">
                                    {{email}}
                                </span>
                                <app-spacer [top]="16"></app-spacer>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="col-12 col-lg-8">
        <div class="row">
            <div class="col-12" *ngIf="bankData.length > 0">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Contas bancárias</span>

                        <ng-container *ngIf="filteredFiles.bank_account">
                            <app-attachment-button
                                [slugArr]="filteredFiles.bank_account"
                                (onSelect)="handleSelect($event,'bank_account')">
                            </app-attachment-button>
                        </ng-container>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <app-table-list [columns]="bankColumns" [data]="bankData">
                    </app-table-list>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <div class="row" [class.flex-row-reverse]="dataCheckForReverseRow()">
            <div class="col-6">
                <div class="col-12 p-0"
                    *ngIf="approval.register.type !== 'PJ' && pepData">
                    <div class="dash-details-card">
                        <div class="dash-details-card-header">
                            <span>Possui relacionamento com PEP</span>
                        </div>
                        <app-spacer [bottom]="25"></app-spacer>

                        <app-table-list [columns]="pepColumns" [data]="pepData">
                        </app-table-list>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 p-0"
                    *ngIf="role === 'shareholder' && !isFund">
                    <div class="dash-details-card">
                        <div class="dash-details-card-header"></div>
                        <div
                            class="dash-details-card-body d-flex align-items-center">
                            <!-- <div class="score">
                                36
                            </div>
                            <app-spacer [right]="30"></app-spacer> -->
                            <div class="score-details">
                                <span class="score-title">
                                    Perfil do investidor
                                </span>
                                <app-spacer [bottom]="5"></app-spacer>
                                <span class="score-label"
                                    *ngIf="approval.register.investor_profile_pj && 
                                    approval.register.investor_profile_pj.length > 0">
                                    {{getRiskLabel(approval.register.investor_profile_pj[0].profile)}}
                                </span>

                                <span class="score-label"
                                    *ngIf="approval.register.investor_profile_pf.length > 0">
                                    {{getRiskLabel(approval.register.investor_profile_pf[0].profile)}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="col-6" *ngIf="role === 'shareholder' && fatca">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Informações fiscais</span>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <div class="dash-details-card-body">
                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca.citizen_us ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    É cidadão natural, naturalizado ou possui
                                    residência fiscal nos EUA?
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca.permanent_resident_us ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    É empresa, ou possui partfe de empresa
                                    constituída nos
                                    EUA e que tenha sede/filial permanente no
                                    país?
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca.has_green_card ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    Possui Visto de Residência Permanente (Green
                                    Card)?
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca.ssn ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    SSN (Social Security Number)
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{fatca.ssn ?? 'Não possuí'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca.us_tax_return ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Entrega declaração de imposto de renda e
                                    bens nos EUA
                                    (US Tax Return) por qualquer motivo não
                                    listado?
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{fatca.description_us_tax ?? 'Não'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12"
                *ngIf="approval.register.type === 'PJ' && economicGroupData.length > 0 && !isFund">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Grupo econômico</span>

                        <ng-container *ngIf="filteredFiles.economic_group">
                            <app-attachment-button
                                [slugArr]="filteredFiles.economic_group"
                                (onSelect)="handleSelect($event,'economic_group')">
                            </app-attachment-button>
                        </ng-container>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <app-table-list [columns]="economicGroupColumns"
                        [data]="economicGroupData" size="19px">
                    </app-table-list>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="!hideDocument">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Documentos</span>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <div class="table-container">
                        <table>
                            <thead>
                                <tr class="alternative-header">
                                    <th>Arquivo</th>
                                    <th>Emissão</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container
                                    *ngFor="let document of documents">
                                    <tr class="alternative-header"
                                        *ngFor="let item of document.data">
                                        <td style="max-width: 500px;">
                                            {{getDocumentLabel(document.slug)}}
                                        </td>
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{formateDate(item.created_at)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div
                                                class="d-flex align-items-center justify-content-end">
                                                <button
                                                    class="button datasheet-button"
                                                    (click)="openUsPerson(item.file)">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <app-spacer [bottom]="28"></app-spacer>
                        </table>
                    </div>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="isAdmin">
                <div class="dash-details-card card-padding-top">
                    <div class="dash-details-card-body">
                        <div class="row">
                            <div class="col-6 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CADASTRO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.created_at | date:
                                    'dd/MM/yyyy HH:mm'}}
                                </span>
                            </div>

                            <div class="col-6 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    ATUALIZAÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.updated_at | date:
                                    'dd/MM/yyyy HH:mm'}}
                                </span>
                            </div>

                            <!-- <div class="col-3 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    0*
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    verificação
                                </span>
                            </div>

                            <div class="col-3 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    0*
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    encerremento
                                </span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <app-spacer [bottom]="24"></app-spacer>
</div>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="selectedFile">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div class="text-center">{{modalFiles[0].type.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-body">
        <ng-container *ngIf="selectedFile">
            <img [src]="selectedFile.url" *ngIf="selectedFile.isImg"
                style="width:100%">

            <iframe [src]="selectedFile.url" *ngIf="!selectedFile.isImg"
                frameborder="0" height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-footer">
        <div class="attachment-modal-navigation">
            <button class="attachment-modal-navigation-arrow"
                (click)="setPrevAttachment()" [disabled]="getDisabled('prev')">
                <i class="fas fa-angle-left"></i>
            </button>

            <div class="pl-3 pr-3">{{selectedFile?.name ?
                urlDecoding(selectedFile.name) : ''}}</div>

            <button class="attachment-modal-navigation-arrow"
                (click)="setNextAttachment()" [disabled]="getDisabled('next')">
                <i class="fas fa-angle-right"></i>
            </button>
        </div>
    </ng-container>
</app-modal>